import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import './Notes.scss';

export const Notes = ({ notes, onDelete }) => (
    <TransitionGroup component="ul" className="list-group">
        {notes.map(note => (
            <CSSTransition 
                key={note.id} 
                classNames={"note"}
                timeout={500}
            >
            <li key={note.id} className="list-group-item note">
                <div>
                    <strong>{note.title}</strong>
                    <small>{note.date}</small>
                </div>

                <button
                    type="button"
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => onDelete(note.id)}
                >x</button>
            </li>
            </CSSTransition>
        ))}
    </TransitionGroup>
);