import React from "react";
import {ReactComponent as Logo} from './logo.svg';
import { Link } from "react-router-dom";

import './Navbar.scss';

export const Navbar = ({ isLogin, userName }) => (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
            
            <Link className="navbar-brand" to="/">
                <Logo className="navbar-brand__logo" />
            </Link>
            
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Главная</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/about">О Нас</Link>
                    </li>
                </ul>

                <form className="d-flex">
                    {isLogin == false ? (
                        <Link className="btn btn-danger" to="/login">Вход для клиентов</Link>
                    ) : (
                        <Link className="btn btn-danger" to="/profile">{userName}</Link>
                    )}
                </form>
                
            </div>    

        </div>
    </nav>
);