// Login
export const LOGIN = "LOGIN";

// Alert
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

// Loading
export const SHOW_LOADING = "SHOW_LOADING";

// Notes
export const ADD_NOTE = "ADD_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";
export const FETCH_NOTES = "FETCH_NOTES";