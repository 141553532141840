import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import {AlertState} from './context/alert/AlertState';
import {ApiState} from './context/api/ApiState';

import {Home} from './pages/Home';
import {Login} from './pages/Login';
import {About} from './pages/About';
import {NotFound} from './pages/NotFound';

import {Navbar} from './components/Navbar/Navbar';

function App() {

  const isLogin = localStorage.getItem('user') ? true : false;
  const userId = isLogin ? JSON.parse(localStorage.getItem('user')).id : null;
  const userName = isLogin ? JSON.parse(localStorage.getItem('user')).name : null;

  
  return (
    <ApiState>
      <AlertState>
        <Router>
            <Navbar isLogin={isLogin} userId={userId} userName={userName} />
            <div className="container py-4">
              <Routes>
                  <Route path="/" element={isLogin==true ? (<Home userId={userId} />) : (<Login />)}/>
                <Route path="/about" element={<About/>}/>
                <Route path="*" element={<NotFound/>}/>
              </Routes>
            </div>
        </Router>
      </AlertState>
    </ApiState>
  );
}

export default App;
