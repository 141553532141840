import React, {useReducer} from 'react';
import axios from 'axios';
import {ApiContext} from './apiContext';
import {apiReducer} from './apiReducer';
import { LOGIN, ADD_NOTE, SHOW_LOADING, DELETE_NOTE, FETCH_NOTES } from "../types";

const apiUrl = process.env.REACT_APP_API_URL;
const apiToken = 'Bearer '.concat(process.env.REACT_APP_API_TOKEN);
//const userId = 1;

// Date
let date = new Date().toISOString().split('T')[0];

export const ApiState = ({children}) => {

    const initialState = {
        isLoading: false,
        notes: []
    }

    const [state, dispatch] = useReducer(apiReducer, initialState);
    const showLoading = () => dispatch({type: SHOW_LOADING});

     
    
    // fetch notes
    const fetchNotes = async () => {
        try {
            showLoading();
            const res = await axios.get(`${apiUrl}/items/todo`, { 'headers': { 'Authorization': apiToken } });
            
            const payload = Object.keys(res.data.data).map(key => {
                return {
                    ...res.data.data[key],
                    id: res.data.data[key].id
                }
            });

            dispatch({type: FETCH_NOTES, payload});
        } catch (error) {
            console.log(error);
        }
    }

    // Login
    const login = async (phone) => {
        const user = {
            phone
        }

        try {
            const res = await axios.get(`${apiUrl}/items/clients?filter[phone]=${user.phone}` , { 'headers': { 'Authorization': apiToken } });
            const payload = {
                ...user,
                id: res.data.data[0].id,
                name: res.data.data[0].name,
                birthday: res.data.data[0].birthday,
            }

            localStorage.setItem('user', JSON.stringify(payload));

            dispatch({type: LOGIN, payload});
        } catch (error) {
            console.log(error);
        }
    }

    // add note
    const addNote = async (title, userId) => {
        const note = {
            title,   
            date: date,
            client: userId,
            status: 'published'
        }

        try {
            
            const res = await axios.post(`${apiUrl}/items/todo`, note , { 'headers': { 'Authorization': apiToken } });

            const payload = {
                ...note,
                id: res.data.data.id
            }

            dispatch({type: ADD_NOTE, payload});
            
        }
        catch (err) {
            console.log(err);
        }

        
    }

    // delete note
    const deleteNote = async (id) => {
        try {
            await axios.delete(`${apiUrl}/items/todo/${id}`, { 'headers': { 'Authorization': apiToken } });
            dispatch({type: DELETE_NOTE, payload: id});
        }
        catch (err) {
            console.log(err);
        }

    }

    return (
        <ApiContext.Provider value={{
            showLoading, login, addNote, deleteNote, fetchNotes,
            isLoading: state.isLoading,
            notes: state.notes
        }}>
            {children}
        </ApiContext.Provider>
    );
};