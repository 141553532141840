import React, {useState, useContext} from 'react';
import {AlertContext} from '../../context/alert/alertContext';
import {ApiContext} from '../../context/api/apiContext';

export const Form = ({userId}) => {

    const [value, setValue] = useState('');

    const alert = useContext(AlertContext);

    const api = useContext(ApiContext);

    const submitHandler = (e) => {
        e.preventDefault();

        if(value.trim()) {
            
            api.addNote(value.trim(), userId).then(() => {
                setValue('');
                alert.show('Упражнение добавлено', 'success');
            }).catch(() => {
                alert.show('Сервер не отвечает', 'danger');
            });

        }
        else {
            alert.show('Пожалуйста, введите значение', 'danger');
        }

    };


    return (
        <form onSubmit={submitHandler}>
            <div className="form-group">
                <input 
                    type="text"
                    className="form-control"
                    placeholder="Новое упражнение"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </div>
        </form>
    );
};
