import React , {useContext} from "react";
import {CSSTransition} from "react-transition-group";
import { AlertContext } from "../../context/alert/alertContext";
import './Alert.scss';

export const Alert = () => {
    
    const {alert, hide} = useContext(AlertContext);

    if(!alert.show) {
        return null;
    }

    return (
        <CSSTransition
            in={alert.show}
            timeout={{
                enter: 500,
                exit: 350
            }}
            classNames={'alert'}
            mountOnEnter
            unmountOnExit 
        >
            <div
                className={`alert alert-${alert.type || 'warning'} alert-dismissible mt-3`}
                role="alert"
            >
                {alert.text}
                <button 
                    onClick={hide}
                    type="button" 
                    className="btn-close" 
                    data-bs-dismiss="alert" 
                    aria-label="Close">
                </button>
            </div>
        </CSSTransition>
    );
}