import { LOGIN, ADD_NOTE, SHOW_LOADING, DELETE_NOTE, FETCH_NOTES } from "../types";

const handlers = {
    // Loading
    [SHOW_LOADING]: state => ({ ...state, isLoading: true }),

    // Login
    [LOGIN]: (state, { payload }) => ({ ...state, login: payload, isLoading: false }),

    // Notes
    [ADD_NOTE]: (state, { payload }) => ({ ...state, notes: [...state.notes, payload] }),
    [DELETE_NOTE]: (state, { payload }) => ({ ...state, notes: state.notes.filter(note => note.id !== payload) }),
    [FETCH_NOTES]: (state, { payload }) => ({ ...state, notes: payload, isLoading: false }),

    DEFAULT: state => state
};

export const apiReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.default;
    return handle(state, action);
};