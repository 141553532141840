import React, { Fragment, useContext, useEffect } from "react";
import {Form}  from "../components/Form/Form";
import { Notes } from "../components/Notes/Notes";
import { Alert } from "../components/Alert/Alert";
import { ApiContext } from "../context/api/apiContext";
import { Loader } from "../components/Loader/Loader";

export const Home = ({userId}) => {

    const {} = useContext(ApiContext);
    const {isLoading, notes, fetchNotes, deleteNote} = useContext(ApiContext);

    useEffect(() => {
        fetchNotes();
    }, []);

    return (
        <Fragment>
            <Form userId={userId} />
            <Alert />
            <hr />

            {isLoading ? (
                <Loader />
            ) : (
            <Notes notes={notes} onDelete={deleteNote}/>
            )}
        </Fragment>
    );
};