import React, {useState, useContext} from 'react';
import {AlertContext} from '../../context/alert/alertContext';
import {ApiContext} from '../../context/api/apiContext';

import './Login.scss';
import {ReactComponent as Logo} from './logo.svg';

export const Login = () => {

    const [value, setValue] = useState('');

    const alert = useContext(AlertContext);

    const api = useContext(ApiContext);

    const submitHandler = (e) => {
        e.preventDefault();

        if(value.trim()) {
            
            api.login(value.trim()).then(() => {
                setValue('');
                alert.show('Добро пожаловать', 'success');
            }).catch(() => {
                alert.show('Сервер не отвечает', 'danger');
            });

        }
        else {
            alert.show('Пожалуйста, введите значение', 'danger');
        }

    };

    return (
        <form onSubmit={submitHandler}>
            <Logo className="mb-4" />
            <h1 className="h3 mb-3 fw-normal">Вход для клиентов!</h1>

            <div className="form-floating mt-2">
                <input 
                    type="text" 
                    className="form-control" 
                    id="floatingInput" 
                    placeholder="Номер телефона" 
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
                <label htmlFor="floatingInput">Номер телефона</label>
            </div>

            <button className="w-100 btn btn-lg btn-primary mt-2" type="submit" onClick={submitHandler}>Вход</button>
            <p className="mt-5 mb-3 text-muted text-center">&copy; 2021 Max Gym</p>
        </form>
    );
};