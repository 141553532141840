import {HIDE_ALERT, SHOW_ALERT} from "../types";

const handlers = {
    [SHOW_ALERT]: (state, {payload}) => ({ ...payload, show: true }),
    [HIDE_ALERT]: state => ({ ...state, show: false }),

    DEFAULT: state => state,
}

export const alertReducer = (state, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT;
    return handler(state, action);
}
